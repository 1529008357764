<template>
  <BSpinner v-if="isLoading" />
  <div class="px-6 pt-6 pb-16 flex flex-col min-h-screen">
    <div class="flex">
      <RocketIcon class="h-10" />
      <div class="text-sm px-4">
        <p>Welcome</p>
        <p class="font-semibold mt-1">{{ name }}</p>
      </div>
    </div>
    <h2 class="text-lg font-semibold mt-8">
      Personalise your experience on Blessmi
    </h2>
    <p class="py-2 text-sm">Choose categories you want to follow</p>
    <form @submit.prevent="handleSubmit">
      <div class="grid gap-4 my-4 grid-height grid-cols-2">
        <label
          class="flex bg-bg-alternate-3 items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-11"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/music.png"
              alt="music"
            />
          </div>
          <span class="p-4 relative z-10">Music</span>
          <CheckBoxIcon
            v-if="checkSelection(1)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Music"
            :value="1"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/religion.png"
              alt="religion"
            />
          </div>
          <span class="p-4 relative z-10">Religion</span>
          <CheckBoxIcon
            v-if="checkSelection(2)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Religion"
            :value="2"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/charity.png"
              alt="charity"
            />
          </div>
          <span class="p-4 relative z-10">Charity</span>
          <CheckBoxIcon
            v-if="checkSelection(4)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Charity"
            :value="4"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-5"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/weddings.png"
              alt="weddings"
            />
          </div>
          <span class="p-4 relative z-10">Weddings</span>
          <CheckBoxIcon
            v-if="checkSelection(3)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Weddings"
            :value="3"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8 col-span-2"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/community.png"
              alt="community"
            />
          </div>
          <span class="p-4 relative z-10">Community Projects</span>
          <CheckBoxIcon
            v-if="checkSelection(5)"
            class="absolute right-4 top-4 z-10"
          />
          />
          <input
            type="checkbox"
            name="Community Projects"
            :value="5"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/education.png"
              alt="education"
            />
          </div>
          <span class="p-4 relative z-10">Education</span>
          <CheckBoxIcon
            v-if="checkSelection(6)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Education"
            :value="6"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-5"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/health.png"
              alt="health"
            />
          </div>
          <span class="p-4 relative z-10">Health</span>
          <CheckBoxIcon
            v-if="checkSelection(7)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Health"
            :value="7"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-11"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/comedy.png"
              alt="comedy"
            />
          </div>
          <span class="p-4 relative z-10">Comedy</span>
          <CheckBoxIcon
            v-if="checkSelection(9)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Comedy"
            :value="9"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/startups.png"
              alt="startups"
            />
          </div>
          <span class="p-4 relative z-10">Startups</span>
          <CheckBoxIcon
            v-if="checkSelection(8)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Startups"
            :value="8"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/models.png"
              alt="models"
            />
          </div>
          <span class="p-4 relative z-10">Models</span>
          <CheckBoxIcon
            v-if="checkSelection(10)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Models"
            :value="10"
            hidden
            v-model="categories"
          />
        </label>
        <label
          class="bg-bg-alternate-3 flex items-end relative rounded overflow-hidden text-text-secondary font-semibold text-sm row-span-8"
        >
          <div class="category-image h-full w-full absolute">
            <img
              class="h-full w-full"
              src="@/assets/images/funerals.png"
              alt="funerals"
            />
          </div>
          <span class="p-4 relative z-10">Funerals</span>
          <CheckBoxIcon
            v-if="checkSelection(11)"
            class="absolute right-4 top-4 z-10"
          />
          <input
            type="checkbox"
            name="Funerals"
            :value="11"
            hidden
            v-model="categories"
          />
        </label>
      </div>
      <div class="text-center">
        <button
          class="bg-btn-bg-primary text-btn-text-primary text-sm rounded w-full mt-8 py-4 font-bold"
          type="submit"
          :disabled="!isFilled"
        >
          Continue
        </button>
        <button
          class="text-text-primary font-semibold text-sm mt-6"
          @click.prevent="handleSkip"
        >
          Do Later
        </button>
      </div>
    </form>
  </div>
  <BModal v-if="isFailure" @close="closeModal">
    <div class="text-center flex flex-col items-center">
      <FailureIcon class="my-4 mx-auto" />
      <h2 class="text-xl text-text-primary font-semibold my-4">Sorry!</h2>
      <p class="text-xs xs:text-sm text-alternate-3">
        {{ error }}
      </p>
      <button
        class="bg-btn-bg-primary max-w-sm text-btn-text-primary rounded w-full mt-10 py-4 font-bold text-sm xs:text-base focus:outline-none"
        @click="closeModal"
      >
        Retry
      </button>
    </div>
  </BModal>
</template>

<script>
import { ref, toRefs, watch, computed } from 'vue';
import { registerCategory } from '@/services/api';
import { useApi } from '@/cmp-functions/useApi';
import BModal from '@/components/ui/BModal';
import BSpinner from '@/components/ui/BSpinner';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default {
  name: 'Category',

  components: {
    BModal,
    BSpinner,
  },

  setup() {
    const categories = ref([]);
    const store = useStore();
    const authUser = computed(() => store.state.auth.authData);

    const [response, setUserCategory, { setIsFailure }] = useApi(
      registerCategory
    );
    const closeModal = () => setIsFailure(false);
    watch(response, (newResponse) => {
      const { isSuccess } = newResponse;
      if (isSuccess) {
        store.commit('auth/TOGGLE_PERSONALISE');
        router.push('/bank');
      }
    });

    const checkSelection = (value) => categories.value.includes(value);

    const isFilled = computed(() => categories.value.length > 0);

    const router = useRouter();
    const handleSubmit = () => {
      if (isFilled.value) {
        const categoryids = categories.value.join(',');
        setUserCategory({ userid: authUser.value.id, categoryids });
      }
    };

    const handleSkip = () => {
      const categoryids = Array(11)
        .fill(2)
        .map((v, index) => index + 1)
        .join(',');
      setUserCategory({ userid: authUser.value?.id, categoryids });
    };

    return {
      ...toRefs(response),
      setIsFailure,
      authUser,
      name: `${authUser.value.firstname} ${authUser.value.lastname}`,
      closeModal,
      isFilled,
      categories,
      handleSubmit,
      handleSkip,
      checkSelection,
    };
  },
};
</script>

<style lang="scss" scoped>
.category-image {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    height: 100%;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.6);
  }

  img {
    object-fit: cover;
    object-position: top;
  }
}

.grid-height {
  min-height: 910px;
  max-height: 1900px;
  height: calc(3 * 80vw);
}
</style>
